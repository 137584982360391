<template>
<section class="dashboard-header">
        <div class="container-fluid">  
            <div class="card-header">
                <h2>{{msg}}</h2>
                <div>   
                </div>
            </div>

            <div id="incidencia" class="row bg-white has-shadow">
                <div class="col-md-7 offset-md-1">
                    <form class="form">
                        <vue-form-generator :schema="schema" :model="model">
                        </vue-form-generator>                        
                        <b-btn class="float-right mr-3 ml-3 mt-3" variant="primary" @click="grabar">Grabar</b-btn>                        
                    </form>
                </div>
                <div class="col-md-4 mt-2">
                    <div class="row">
                        <div class="col-md-12">
                            <avatar 
                                username="Jane Doe"
                                :src="imageData"
                                :size = "120"
                            ></avatar>
                        </div>                    
                    </div>
                    <div class="row">
                        <div class="col-md-8 mt-3">
                            <button class="btn btn-link ml-2" @click='toggle = !toggle'>Subir foto </button>
                        </div>
                    </div>
                    <div class="row" v-show='toggle'>
                        <div class="col-md-8 mt-3">                            
                            <div class="file-upload-form">
                                <input id="upload" type="file" @change="previewImage" >
                            </div>                                                        
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
</template>

<script>
import axios from 'axios';
import Vue from 'vue'
import VueFormGenerator from 'vue-form-generator'
import Avatar from 'vue-avatar'
import Compress from 'compress.js'

let compress = new Compress();

//import 'vue-form-generator/dist/vfg.css' 
Vue.use(VueFormGenerator)

export default {
     mounted: function() {
        this.previewImage();
        this.getPhoto();
        this.getTablas();
      },
    data() {
        return {
            toggle: false,
            msg: 'Editar Usuario',
            tabla: 'usuarios',
            email: this.$route.params.email,
            imageData: "", 
            roles: [],
            zonas: [],           
            model: {},
            schema: {},                        
        }        
    },
    components: {
      Avatar
    },
    methods: {        
        previewImage: function(event) {
            compress.attach('#upload', {
                maxWidth: 400,
                quality: .99
            }).then((data) => {
                var image = data[0];
                this.imageData = image.prefix + image.data; 
                var emailUser = btoa(this.email) + '.ava';
                var datos = {"nameFile":'', "emailUser": emailUser, "avatar": image.data};
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$cookies.get('token');
                axios.post(process.env.VUE_APP_ROOT_API + "api/secure/" + this.tabla + "/uploadavatar", datos)
                .then((response) => {                
                    this.toggle = false;

                    this.$root.$emit('clickedSomething')

                    //console.log(JSON.stringify(response.data));
                    //this.$router.replace({ path: '/portal/' + this.tabla });                                                                 
                }, (error) => {
                    console.log("Error: " + JSON.stringify( error.response.data.message) );
                })
            })
            
        },
        getTablas() {
            var token =  this.$cookies.get('token');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            axios.all([
                this.getZonas(), //or direct the axios request
                this.getRoles(),
                this.getUsuario()
            ])
            .then(axios.spread((zonasResponse, rolesResponse, usuariosResponse) => {
                this.zonas = zonasResponse.data;
                this.roles = rolesResponse.data;
                this.model = usuariosResponse.data;
                this.schema = {fields: this.getSchema()};
            }))
            
        },
        getZonas() {
            return axios.get(process.env.VUE_APP_ROOT_API + "api/secure/zonas");        
        },
        getRoles() {
            return axios.get(process.env.VUE_APP_ROOT_API + "api/secure/roles");        
        },        
        getUsuario(){
            var emailUser = btoa(this.email) + '.ava';            
            return axios.get(process.env.VUE_APP_ROOT_API + "api/secure/usuarios/" + emailUser);            
        },
        getSchema(){
            var regiones = [{'id':'XXXX','nombre': 'NO ASIGNADA'},{'id': 'TSUR', 'nombre': 'TICKETS SUR'}, {'id': 'TBAJ', 'nombre': 'TICKETS BAJIO'}, {'id': 'TNOR', 'nombre': 'TICKETS NORTE'}];
            var titulos = ['ID', 'NOMBRE','CEL', 'TEL', 'REGION', 'ROL', 'EMAIL', 'PASS', 'PIN'];
            var columnas = ['id', 'name', 'cel', 'tel', 'region', 'rol', 'email', 'pass', 'pin'];
            var arr = [];
            for (let i in titulos) { 
            if( i*1 === 0 ){
                arr.push({'label': titulos[i], 'model': columnas[i], type: "input", inputType: "text", visible: false});              
            }else{
                if( columnas[i] === 'pass' ){
                    arr.push({'label': titulos[i], 'model': columnas[i], type: "input", inputType: "password"});                          
                }else if(columnas[i] === 'region'){
                    arr.push({'label': titulos[i], 'model': columnas[i], type: "select", values: regiones, disabled: this.$store.getters.userAdmin === "true" ? false: true,
                    selectOptions: {hideNoneSelectedText: true, id: 'id', name: 'nombre'}});
                }else if(columnas[i] === 'rol'){
                    arr.push({'label': titulos[i], 'model': columnas[i], type: "select", values: this.roles, disabled: this.$store.getters.userAdmin === "true" ? false: true,
                    selectOptions: {id: 'id', name: 'nombre'}});            
                }else if(columnas[i] === 'email'){
                    arr.push({'label': titulos[i], 'model': columnas[i], type: "input", inputType: "text", disabled: this.$store.getters.userAdmin === "true" ? false: true});
                }else{
                    arr.push({'label': titulos[i], 'model': columnas[i], type: "input", inputType: "text"});
                }                        
            }
            }
            return arr;
        },
        getPhoto(){
            var emailUser = btoa(this.email) + '.ava';
            var datos = {"nameFile":'', "emailUser": emailUser, "avatar": ''};
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$cookies.get('token');
            axios.post(process.env.VUE_APP_ROOT_API + "api/secure/usuarios/avatar", datos)
            .then((response) => {                
                this.imageData = "data:image/jpeg;base64," + response.data.avatar;
                //this.$router.replace({ path: '/portal/' + this.tabla });                                                                 
            }, (error) => {
                console.log("Error: " + JSON.stringify( error.response.data.message) );
            })
        },
        grabar(){          
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$cookies.get('token');
            axios.post(process.env.VUE_APP_ROOT_API + "api/secure/" + this.tabla, this.model)
            .then((response) => {                
                this.$router.push({ name: "Inicio"});
            }, (error) => {
                console.log("Error: " + JSON.stringify( error.response.data) );
            })
        }        
    }

}
</script>

<style>
    small.text-info{color: #7000ff}
    div#incidencia{margin-top: 10px; padding-bottom: 20px;}
    section.dashboard-header{padding: 10px 0;}  
    div.autocomplete{width: 400px}

    
</style>
